import { collection, doc, getDoc, updateDoc, addDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import omit from 'lodash/omit';
import { FirestoreInstance } from '../../contexts/Firebase';

export const addNewSequence = async ({ uid, sequence, id, title }) => {
  const edit = !!id && id !== 'new';
  const now = Timestamp.now();

  const docRef = doc(FirestoreInstance, 'users', uid);
  const colRef = collection(docRef, 'sequences');

  if (edit) {
    const seqRef = doc(FirestoreInstance, 'users', uid, 'sequences', id);
    const editSequence = await updateDoc(seqRef, {
      ...sequence,
      title,
      modifiedAt: now,
    });

    return editSequence;
  }

  const nuSequence = await addDoc(colRef, {
    title,
    ...sequence,
    createdAt: now,
  });
  return nuSequence;
};

export const addNewScheduleToUser = async ({ uid, schedule, id }) => {
  const edit = !!id;
  const now = Timestamp.now();

  const docRef = doc(FirestoreInstance, 'users', uid);
  const colRef = collection(docRef, 'schedules');

  if (edit) {
    const seqRef = doc(FirestoreInstance, 'users', uid, 'schedules', id);
    const editSchedule = await updateDoc(seqRef, {
      ...omit(schedule, 'id'),
      modifiedAt: now,
    });

    return editSchedule;
  }

  const nuSchedule = await addDoc(colRef, {
    ...omit(schedule, 'id'),
    createdAt: now,
  });
  return nuSchedule;
};

export const deleteSequence = ({ uid, id }) => {
  const docRef = doc(FirestoreInstance, `users/${uid}/sequences/${id}`);
  return deleteDoc(docRef);
};

export const deleteSchedule = async ({ uid, id }) => {
  console.log('YOLO DELETE', uid, id);
  const docRef = doc(FirestoreInstance, `users/${uid}/schedules/${id}`);
  return deleteDoc(docRef);
};

export const getSequence = async ({ uid, id }) => {
  const sequenceRef = doc(FirestoreInstance, `users/${uid}/sequences`, id);
  const resp = await getDoc(sequenceRef);
  return resp.data();
};

export const getSchedule = async ({ uid, id }) => {
  const scheduleRef = doc(FirestoreInstance, `users/${uid}/schedules`, id);
  const resp = await getDoc(scheduleRef);
  return { ...resp.data(), id };
};
