import PropTypes from 'prop-types';
// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import { UploadIllustration } from '../../assets';

// ----------------------------------------------------------------------
BlockContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.any,
};

export default function BlockContent({
  title,
  description = (
    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
      Drop files here or click to&nbsp;
      <Typography variant="body2" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
        browse
      </Typography>
      &nbsp; your machine
    </Typography>
  ),
}) {
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction={{ xs: 'column', md: 'row' }}
      sx={{ width: 1, textAlign: { xs: 'center', md: 'left' }, px: 2 }}
    >
      <UploadIllustration sx={{ width: 220 }} />

      <Box sx={{ p: 3 }}>
        <Typography gutterBottom variant="h5">
          {title || 'Drop or Select file'}
        </Typography>

        {description}
      </Box>
    </Stack>
  );
}
