import { v4 as uuid } from 'uuid';

// Basic objects
export const defaultEmail = (index) => ({
  subject: '',
  body: '',
  index: index || 0,
  id: `mail-${uuid()}`,
  abPercent: 100,
  signature: true,
});

export const defaultStep = (index) => ({
  index: index || 0,
  type: 'email',
  delay: 2,
  abTest: false,
  emails: {
    0: defaultEmail(),
  },
  id: `step-${uuid()}`,
});

// Basic collections
export const defaultSteps = (data) => ({ 0: { ...defaultStep(), ...data } });

export const defaultSequence = (index) => ({
  index: index || 0,
  steps: defaultSteps({ delay: 0 }),
  id: `seq-${uuid()}`,
});

export const initSequences = () => ({ 0: defaultSequence() })

export const initOuterSequence = () => ({ ...initSequences() });
