// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: '/dashboard',
    campaigns: '/campaigns',
    prospects: '/prospects',
    templates: '/templates',
    chat: '/chat',
    mailbox: '/mailbox',
    clients: '/clients',
    automations: '/automations',
    users: '/users',
    app: '/app',
    terms: '/terms-and-conditions',
    privacy: '/privacy-policy',
  },
  templates: {
    root: '/templates',
    sequences: {
      root: '/templates/sequences',
      list: '/templates/sequences/list',
      new: '/templates/sequences/new',
    },
    schedules: {
      root: '/templates/schedules',
      list: '/templates/schedules/list',
      edit: '/templates/schedules/edit',
      new: '/templates/schedules/new',
    },
  },
  prospects: {
    root: '/prospects',
    list: '/prospects/list',
    upload: '/prospects/upload',
  },
  campaigns: {
    root: '/campaigns',
    list: '/campaigns/list',
    new: '/campaigns/new',
    edit: '/campaigns/',
  },
  automations: {
    root: '/automations',
    list: '/automations/list',
    edit: '/automations/edit',
    new: '/automations/new',
  },
  invitations: {
    root: '/invitations',
    list: '/invitations/list',
    edit: '/invitations/edit',
    new: '/invitations/new',
  },
  mailbox: {
    root: '/mailbox',
    new: '/mailbox/new',
    conversation: '/mailbox/:threadId',
  },
  clients: {
    root: '/clients',
    profile: '/clients/profile',
    list: '/clients/list',
    new: '/clients/new',
    edit: '/clients/list/edit/',
  },
  team: {
    root: '/team',
    new: '/team/new',
    edit: '/team/edit',
    profile: '/team/profile',
  },
  mail: {
    root: '/mail',
    all: '/mail/all',
  },
  chat: {
    root: '/chat',
    new: '/chat/new',
    conversation: '/chat/:conversationKey',
  },
  calendar: '/calendar',
  kanban: '/kanban',
  user: {
    root: '/user',
    profile: '/user/profile',
    subscription: '/user/profile/subscription',
    cards: '/user/cards',
    list: '/user/list',
    newUser: '/user/new',
    editById: `/user/reece-chung/edit`,
    account: '/user/account',
    team: '/user/team',
  },
};

export const PATH_DOCS = 'https://www.maility.io/help-center';
