import { setDoc, deleteDoc, updateDoc, Timestamp, doc } from 'firebase/firestore';
import { omit } from 'lodash';
import { FirestoreInstance } from '../../contexts/Firebase';

export const addInvitation = async ({ email: originalEmail, roles, uid }) =>
  setDoc(
    doc(FirestoreInstance, 'invitations', originalEmail.toLowerCase()),
    {
      email: originalEmail.toLowerCase(),
      roles,
      createdAt: Timestamp.now(),
      createdBy: uid,
      status: 'active',
    },
    { merge: true }
  );

export const editInvitation = async (invitation) => {
  await setDoc(
    doc(FirestoreInstance, 'invitations', invitation.email),
    {
      ...invitation,
      updatedAt: Timestamp.now(),
    },
    { merge: true }
  );
};

export const editInvitationField = async ({ email, field }) => {
  const invitationRef = doc(FirestoreInstance, `/invitations/${email}`);

  await updateDoc(invitationRef, {
    ...omit(field, 'createdAt', 'id'),
    modifiedAt: Timestamp.now(),
  });

  return invitationRef.id;
};

export const deleteInvitation = (id) => deleteDoc(doc(FirestoreInstance, `/invitations`, id));
