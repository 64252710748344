import { ref, uploadBytesResumable, getDownloadURL, getStorage, uploadString } from 'firebase/storage';
import uuidv4 from '../utils/uuidv4';

export const imageUpload = async (file) => {
  const metadata = {
    contentType: 'image/*',
  };

  const storage = getStorage();
  const storageRef = ref(storage, `/images/${file.name}`);
  await uploadBytesResumable(storageRef, file, metadata);

  const download = await getDownloadURL(storageRef);

  return download;
};

export const base64ImageUpload = async (file, title) => {
  const storage = getStorage();
  const storageRef = ref(storage, `/images/${encodeURI(title)}`);
  await uploadString(storageRef, file, 'data_url');

  const download = await getDownloadURL(storageRef);

  return download;
};

export const uploadAttachments = async (file, path = 'attachments') => {
  const randomId = uuidv4().split('-')[0];
  const name = `${randomId}-${file.name.trim()}`;
  const storage = getStorage();
  const storageRef = ref(storage, `/${path}/${name}`);
  await uploadBytesResumable(storageRef, file);

  const download = await getDownloadURL(storageRef);

  return [download, name];
};

export const getFile = async (name, path = 'attachments') => {
  const storage = getStorage();
  const storageRef = ref(storage, `${path}/${name}`);
  const download = await getDownloadURL(storageRef);

  if (download) {
    return download;
  }
};
