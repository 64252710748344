import PropTypes from 'prop-types';
import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';

const HomeErrorFallback = ({ children }) => {
  const [tokenAgain, setTokenAgain] = useState(false);
  const { getTokenAgain } = useAuth();
  return (
    <ErrorBoundary
      FallbackComponent={LoadingScreen}
      onError={(error) => {
        if (error.code === 'permission-denied') {
          if (!tokenAgain) {
            setTokenAgain(true);
            getTokenAgain();
          }
          // window.location.reload();
        }
      }}
      // onReset={resetErrorBoundary}
      // resetKeys={[user]}
    >
      {children}
    </ErrorBoundary>
  );
};

HomeErrorFallback.propTypes = {
  children: PropTypes.node,
};

export default HomeErrorFallback;
