import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { Dialog, Box, Paper } from '@mui/material';

// ----------------------------------------------------------------------

DialogAnimate.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  sx: PropTypes.object,
  variants: PropTypes.object
};

export default function DialogAnimate({ open = false, onClose, children, sx, ...other }) {
  return (
    <>
      {open && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={open}
          onClose={onClose}
          PaperComponent={(props) => (
            <Box
              component={m.div}
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box onClick={onClose} sx={{ width: '100%', height: '100%', position: 'fixed' }} />
              <Paper sx={sx} {...props}>
                {props.children}
              </Paper>
            </Box>
          )}
          {...other}
        >
          {children}
        </Dialog>
      )}
      </>
    // </AnimatePresence>
  );
}
