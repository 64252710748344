import yn from 'yn';
import { useInterval } from './useInterval';
import { clientrunner, batchRunner } from '../backend/clientrunner';
import { webhookRunnerCallable } from '../backend/webhookRunner';

const useScheduledBackendTasks = () => {
  useInterval(() => {
    if (
      process.env.NODE_ENV !== 'production' &&
      yn(process.env.REACT_APP_LOCAL_DEV) &&
      yn(process.env.REACT_APP_LOCAL_MAILER)
    )
      clientrunner();
  }, 60 * 1000);

  // BATCHRUNNER
  useInterval(() => {
    if (
      process.env.NODE_ENV !== 'production' &&
      yn(process.env.REACT_APP_LOCAL_DEV) &&
      yn(process.env.REACT_APP_LOCAL_BATCHRUNNER)
    )
      batchRunner();
  }, 10 * 1000);

  // WEBHOOKRUNNER
  useInterval(() => {
    if (
      process.env.NODE_ENV !== 'production' &&
      yn(process.env.REACT_APP_LOCAL_DEV) &&
      yn(process.env.REACT_APP_LOCAL_WEBHOOK)
    )
      webhookRunnerCallable();
  }, 10 * 1000);

  // NATURAL LANGUAGE
  // useInterval(() => {
  //   naturalLanguageQuickstart();
  // }, 10 * 1000);

  return null;
};

export default useScheduledBackendTasks;
