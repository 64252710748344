import { createSvgIcon } from '@mui/material/utils';
import Iconify from '../components/Iconify';

export const GoogleIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.242 6.679a11.509 11.509 0 0 1 3.114-3.942C6.147 1.257 8.202.365 10.506.092c2.707-.32 5.25.192 7.603 1.6.584.35 1.13.748 1.652 1.179.13.105.115.167.005.273a502.334 502.334 0 0 0-3.195 3.2c-.125.124-.192.115-.326.014-3.104-2.39-7.63-1.744-9.945 1.418a6.983 6.983 0 0 0-.967 1.806c-.02.057-.058.11-.086.168-.618-.47-1.241-.94-1.854-1.414-.719-.55-1.437-1.101-2.151-1.657Z"
      fill="#E94335"
    />
    <path
      d="M5.246 14.243c.206.455.379.93.638 1.356 1.087 1.782 2.639 2.927 4.684 3.334 1.845.369 3.617.12 5.27-.805l.168-.086c.028.028.052.062.081.086 1.236.958 2.477 1.916 3.712 2.874-.593.59-1.269 1.064-1.988 1.476-2.083 1.188-4.34 1.667-6.716 1.484-2.96-.23-5.513-1.398-7.621-3.506-.925-.924-1.686-1.969-2.237-3.162.513-.392 1.025-.78 1.538-1.173.824-.628 1.648-1.25 2.471-1.878Z"
      fill="#34A853"
    />
    <path
      d="M19.804 21.002c-1.235-.958-2.476-1.916-3.712-2.874a.699.699 0 0 1-.081-.086c.426-.33.862-.652 1.211-1.073a5.57 5.57 0 0 0 1.174-2.376c.024-.11.005-.149-.105-.144-.058.005-.11 0-.168 0-1.954 0-3.914-.005-5.868.005-.216 0-.264-.058-.259-.264.01-1.389.01-2.778 0-4.167 0-.178.048-.226.225-.226 3.584.005 7.167.005 10.755 0 .153 0 .215.039.254.202.292 1.317.273 2.64.095 3.97a12.979 12.979 0 0 1-.8 3.09 10.99 10.99 0 0 1-2.596 3.857c-.043.033-.086.057-.125.086Z"
      fill="#4285F3"
    />
    <path
      d="M5.247 14.243c-.824.627-1.648 1.25-2.472 1.878-.513.388-1.025.78-1.538 1.173-.398-.737-.656-1.518-.867-2.323-.402-1.557-.465-3.138-.244-4.723a10.99 10.99 0 0 1 1.111-3.564c.719.55 1.432 1.106 2.15 1.657.619.474 1.237.944 1.855 1.413-.106.513-.254 1.016-.302 1.543a7.434 7.434 0 0 0 .278 2.812c.019.038.024.086.029.134Z"
      fill="#FABB06"
    />
  </svg>,
  'GoogleIcon'
);

export const TwitterIcon = createSvgIcon();

export const OutlookIcon = () => <Iconify icon={'vscode-icons:file-type-outlook'} />;

export const IntegrationIcons = {
  gmail: GoogleIcon,
  twitter: TwitterIcon,
  microsoft: OutlookIcon,
};
