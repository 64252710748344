import percentRound from 'percent-round';
import { mapValues } from 'lodash';

export const percentageRounder = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

export const roundCampaignPercentages = (newPriorityMap, newCampaign) => {
  // We add this "phantom campaign" to the priority set. It's default value is whatever round percent: 25% if 4 campaigns, 33% if 3, etc
  if (newCampaign) {
    newPriorityMap[newCampaign] = { percentage: 100 / (Object.keys(newPriorityMap)?.length + 1) ?? 1 };
  }

  const newPercentagesArray = Object.values(mapValues(newPriorityMap, (campaign) => campaign.percentage));

  // Here we round percentages
  const newPercentagesRounded = percentRound(newPercentagesArray);

  newPriorityMap = Object.fromEntries(
    Object.keys(newPriorityMap).map((id, index) => [id, { percentage: newPercentagesRounded[index] }])
  );
  return newPriorityMap;
};

export const evenPercentages = (campaigns) => {
  const newPercentages = Object.fromEntries(
    campaigns?.map((campaign) => [campaign?.id, { percentage: campaigns?.length > 1 ? 100 / campaigns?.length : 100 }])
  );
  return newPercentages;
};
