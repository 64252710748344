import { useEffect } from 'react';
import useCookie from 'react-use-cookie';
import { logger } from '../utils/logger';
import useAuth from './useAuth';

const useUIDCookie = () => {
  const { user } = useAuth();

  const [uid, setUid] = useCookie('maility-uid', null);
  const [email, setEmail] = useCookie('maility-email', null);
  const [secret, setSecret] = useCookie('maility-secret', null);

  useEffect(() => {
    setUid(user?.orgId);
    setEmail(user?.email);
    setSecret(process.env.REACT_APP_GOOGLE_CLIENT_ID);
  }, [user, setUid, setEmail, setSecret]);

  useEffect(() => {
    logger.dot('Set Maility Cookies:', uid, email, secret);
  }, [uid, email, secret]);

  return {
    uid,
    email,
    secret,
  };
};

export default useUIDCookie;
