import { isValid } from 'date-fns';

export const isObject = (o) => Object.prototype.toString.call(o) === '[object Object]';

export const dropNullUndefined = (d) =>
  Object.entries(d).reduce(
    (acc, [k, v]) => (v == null || v === 'null' ? acc : { ...acc, [k]: isObject(v) ? dropNullUndefined(v) : v }),
    {}
  );

export const removeEmpty = (d) => Object.fromEntries(Object.entries(d).filter((v) => v[1]));

export const firestoreTimestampToDate = (d) =>
  new Date((d.seconds || d._seconds) * 1000 + (d.nanoseconds || d._nanoseconds) / 1000000);

export const dateTransformer = (d) => (d.seconds ? firestoreTimestampToDate(d) : d.toDate());

export const dateEvaluateAndTransform = (d) => (isValid(d) ? d : dateTransformer(d));

// ----------------------------------------------------------------------

export const descendingComparator = (a, b, orderBy) => {
  const c = orderBy === 'createdAt' ? comp(a[orderBy]) : a[orderBy];
  const d = orderBy === 'createdAt' ? comp(b[orderBy]) : b[orderBy];
  if (d < c) {
    return -1;
  }
  if (d > c) {
    return 1;
  }
  return 0;
};

const comp = (d) => dateEvaluateAndTransform(d).getTime();

export const getComparator = (order, orderBy) =>
  order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

export const applySortFilter = (array, comparator, query) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  if (query) {
    return array.filter(
      (report) =>
        report?.client?.displayName?.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
        report?.client?.company?.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
};