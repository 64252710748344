export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

export const nuPositions = {
  'UX Designer': 'CEO',
  'Full Stack Designer': 'Founder',
  'Backend Developer': 'CMO',
  'Project Manager': 'CEO',
  Leader: 'Founder',
  'UI Designer': 'CMO',
  'UI/UX Designer': 'CEO',
  'Front End Developer': 'Founder',
  'Full Stack Developer': 'Founder',
};

export const repositionEl = ({ position, ...contact }) => ({ ...contact, position: nuPositions[position] });
export const reposition = (array) => array.map((el) => reposition(el));

export const stringToBoolean = (string) => JSON.parse(string.toLowercase());

export const numsToLetters = (num) => String.fromCharCode(97 + Number(num));