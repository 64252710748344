import { useState } from 'react';
import { Alert } from '@mui/material';
import useControlBanner from '../hooks/useControlBanner';
import Iconify from './Iconify';
import HomeErrorFallback from '../error-boundaries/HomeErrorFallback';

const MailityExec = () => {
  const [banner] = useControlBanner();
  const [scrolledItem, setScrolledItem] = useState(true);
  const [hoveredItem, setHoveredItem] = useState(false);

  const showBannerMessage = () => {
    if (window.scrollY >= 63) {
      setScrolledItem(false);
    } else {
      setScrolledItem(true);
      setHoveredItem(false);
    }
  };

  window.addEventListener('scroll', showBannerMessage);
  return banner && banner?.message ? (
    <div>
      {scrolledItem ? (
        <Alert sx={{ mt: 1, position: 'relative' }} severity={banner?.severity || 'warning'}>
          {scrolledItem ? banner?.message : null}
        </Alert>
      ) : (
        <Iconify
          onMouseEnter={() => setHoveredItem(true)}
          onMouseLeave={() => setHoveredItem(false)}
          sx={{ mt: '6px', color: '#919EAB' }}
          icon={'material-symbols:info'}
          width={22}
          height={22}
        />
      )}
      {hoveredItem && !scrolledItem && (
        <Alert sx={{ mt: 1, position: 'absolute', left: '60%' }} severity={banner?.severity || 'warning'}>
          {banner?.message}
        </Alert>
      )}
    </div>
  ) : null;
};

const MailityExecWithErrorFallback = () => (
  <HomeErrorFallback>
    <MailityExec />
  </HomeErrorFallback>
);

export default MailityExecWithErrorFallback;
