const slots = {
  1: [
    { start: '09:00', end: '12:00' },
    { start: '14:00', end: '19:00' },
  ],
  2: [
    { start: '09:00', end: '12:00' },
    { start: '14:00', end: '19:00' },
  ],
  3: [
    { start: '09:00', end: '12:00' },
    { start: '14:00', end: '19:00' },
  ],
  4: [
    { start: '09:00', end: '12:00' },
    { start: '14:00', end: '19:00' },
  ],
  5: [
    { start: '09:00', end: '12:00' },
    { start: '14:00', end: '19:00' },
  ],
};

export const defaultSchedule = {
  title: '',
  slots,
  holidays: ['US', 'GB'],
  timezone: {
    abbreviation: 'ET',
    alternativeName: 'Eastern Time',
    countryCode: 'US',
    countryName: 'United States',
    fullAlternativeName: '(UTC-05:00) Eastern Time',
    fullName: '(UTC-05:00) America/New_York',
    name: 'America/New_York',
    offset: '-05:00',
    rawOffsetInMinutes: -300,
  },
};
