import {httpsCallable} from "firebase/functions";
import { deleteDoc, doc, updateDoc, Timestamp } from 'firebase/firestore';
import { omit } from 'lodash';
import { FirestoreInstance, FirebaseFunctions } from '../../contexts/Firebase';

export const addAutomationToUserCallable = httpsCallable(FirebaseFunctions, 'addWebhookToUserFunction');
export const editAutomationCallable = httpsCallable(FirebaseFunctions, 'editWebhookFunction');
export const testWebhookCallable = httpsCallable(FirebaseFunctions, 'testWebhook');

export const editAutomationField = async ({ uid, id, field }) => {
  const automationRef = doc(FirestoreInstance, `/users/${uid}/webhooks/${id}`);

  await updateDoc(automationRef, {
    ...omit(field, 'createdAt', 'id'),
    modifiedAt: Timestamp.now(),
  });

  return automationRef.id;
};

export const deleteAutomation = async ({ uid, id }) => {
  // await deleteActivityInAutomation(id);
  const automationRef = doc(FirestoreInstance, `/users/${uid}/webhooks`, id);
  await deleteDoc(automationRef);
};