import { useEffect, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';
import { NAVBAR } from '../config';
import useAuth from '../hooks/useAuth';
import useCollapseDrawer from '../hooks/useCollapseDrawer';
import { logger } from '../utils/logger';

const Intercom = () => {
  const { boot, shutdown } = useIntercom();
  const { isCollapse } = useCollapseDrawer();
  const { user } = useAuth();

  const options = useMemo(() => {
    const width = isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.BASE_WIDTH;

    return {
      userId: user?.orgId,
      email: user?.email,
      name: user?.displayName,
      horizontalPadding: width / 2 - 8,
      avatar: user?.photoURL,
      company: user?.company,
    };
  }, [isCollapse, user]);

  useEffect(() => {
    // don't proceed if url contains "callback"
    if (window.location.href.includes('callback')) return;
    boot(options);
  }, [boot, options]);

  useEffect(
    () => () => {
      shutdown();
      logger.action('Shutting down intercom');
    },
    [shutdown]
  );

  return null;
};

export default Intercom;
