import { loadStripe } from '@stripe/stripe-js';
import { httpsCallable } from 'firebase/functions';
import { FirebaseFunctions } from './Firebase';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const initializeStripe = async () => {
  if (stripePromise) {
    const stripe = await stripePromise;
    return stripe;
  }
};

export const createCheckoutSessionCallable = httpsCallable(FirebaseFunctions, 'createCheckoutSession');
