export const splitName = (name = '') => {
  const [firstName, ...lastName] = name.split(' ').filter(Boolean);
  return {
    firstName,
    lastName: lastName.join(' '),
  };
};


export const containsStripeRole = ({ role, accessibleRoles }) => accessibleRoles && accessibleRoles.includes(role);

export const containsRole = ({ role, accessibleRoles }) => accessibleRoles && accessibleRoles.includes(role);