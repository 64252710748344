import { collection, doc, updateDoc } from 'firebase/firestore';
import { FirestoreInstance } from '../../contexts/Firebase';

export const updateProspect = async ({ id, status, campaignId }) => {
  const prospectRef = doc(collection(FirestoreInstance, `campaigns/${campaignId}/people`), id);
  try {
    await updateDoc(prospectRef, { leadStatus: status });
  } catch (error) {
    throw new Error(error);
  }
};
