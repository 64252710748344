import { useFirestoreDocData, useFirestore } from 'reactfire';
import { doc } from 'firebase/firestore';

export default function useControlBanner() {
  const firestore = useFirestore();
  const ref = doc(firestore, 'control/banner');
  const { status, data: control } = useFirestoreDocData(ref, {
    idField: 'id',
  });

  return [control, status];
}
