import { collection, doc, setDoc, addDoc, Timestamp } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { FirestoreInstance, FirebaseFunctions } from '../../contexts/Firebase';

// NOTIFICATIONS

export const createNotification = async ({ title, description, avatar, type, uid }) => {
  const now = Timestamp.now();

  const Notification = {
    title,
    description,
    avatar,
    type,
    createdAt: now,
    uid,
    isUnread: true,
  };

  try {
    await addDoc(collection(FirestoreInstance, 'notifications'), Notification);
    return 'success';
  } catch (err) {
    return err.message;
  }
};

export const editNotification = async ({ uid, id, ...data }) => {
  const now = Timestamp.now();
  try {
    const notRef = doc(collection(FirestoreInstance, `/users/${uid}/notifications`), id);
    await setDoc(notRef, { ...data, lastModified: now }, { merge: true });
    return 'success';
  } catch (err) {
    return err.message;
  }
};

export const seeNotification = (uid, id) => editNotification({ uid, id, isUnread: false });

export const editABunchOfNotifications = httpsCallable(FirebaseFunctions, 'editNotifications');

export const markAllNotificationsRead = (uid, list) =>
  editABunchOfNotifications({ uid, list, data: { isUnread: false } });
