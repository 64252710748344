import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';

export default function SelectFree({ name, rules, options, multiple, ...rest }) {
  const { control } = useFormContext();

  const filterOptions = createFilterOptions({ limit: 2500 });

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={multiple ? [] : null}
      render={({ field: { ref, ...field }, fieldState: { error, invalid } }) => (
        <Autocomplete
          {...field}
          multiple={multiple}
          freeSolo
          handleHomeEndKeys
          options={options}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField {...params} {...rest} inputRef={ref} error={invalid} helperText={error?.message} />
          )}
          onChange={(e, value) => field.onChange(value)}
          onInputChange={(_, data) => {
            if (data) field.onChange(data);
          }}
          {...rest}
        />
      )}
    />
  );
}

SelectFree.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  multiple: PropTypes.bool,
  options: PropTypes.array,
  limit: PropTypes.number,
};
