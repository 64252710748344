import PropTypes from 'prop-types';
import { Container, Alert, AlertTitle, Typography } from '@mui/material';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

RoleBasedGuard.propTypes = {
  accessible: PropTypes.bool,
  // stripeRole: PropTypes.string,
  // accessibleRoles: PropTypes.array, // Example ['admin', 'leader']
  children: PropTypes.node,
};

export default function RoleBasedGuard({ accessible, children }) {
  const { user } = useAuth();
  if (!user && (!user.roles || !user.stripeRole)) return null;

  if (!accessible) {
    return (
      <Container>
        <Alert severity="warning">
          <AlertTitle>Permission Denied</AlertTitle>
          <Typography>You do not have permission to access this page</Typography>
          <Typography>If you just purchased a Maility plan, please wait a few seconds and reload this page</Typography>
        </Alert>
      </Container>
    );
  }

  return children;
}
