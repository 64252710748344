import PropTypes from 'prop-types';
import { memo, useContext } from 'react';
// @mui
import { Stack } from '@mui/material';
//
import { AuthContext } from '../../../contexts/AuthContext';
import { NavListRoot } from './NavList';
import navChecker from '../navChecker';

// ----------------------------------------------------------------------

const hideScrollbar = {
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

NavSectionHorizontal.propTypes = {
  navConfig: PropTypes.array,
};

function NavSectionHorizontal({ navConfig }) {
  const { user } = useContext(AuthContext);
  if (!user && (!user.roles || !user.stripeRole)) return null;

  return (
    <Stack direction="row" justifyContent="center" sx={{ bgcolor: 'background.neutral', borderRadius: 1, px: 0.5 }}>
      <Stack direction="row" sx={{ ...hideScrollbar, py: 1 }}>
        {navConfig.map((group) => {
          console.log('YOLO g', group);
          return (
            <Stack key={group.subheader ?? Math.random()} direction="row" flexShrink={0}>
              {group.items.map((item) =>
                navChecker(item, user) ? <NavListRoot key={item.title ?? Math.random()} list={item} /> : null
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}

export default memo(NavSectionHorizontal);
