import { Alert } from '@mui/material';
import useAuth from '../hooks/useAuth';
import useUserRealtime from '../hooks/useUserRealtime';
import { hideBanner } from '../backend/firestore/usersClients';
import Iconify from './Iconify';
import { IconButtonAnimate } from './animate';

const RealtimeBanner = () => {
  const { user } = useAuth();

  const [userRealTime] = useUserRealtime(user?.uid);

  return userRealTime && userRealTime?.banner && !userRealTime?.banner?.hide ? (
    <Alert
      severity={userRealTime?.banner?.severity || 'warning'}
      action={
        <IconButtonAnimate aria-label="close" color="inherit" size="small" onClick={() => hideBanner(user?.uid)}>
          <Iconify icon="eva:close-fill" />
        </IconButtonAnimate>
      }
    >
      {user?.banner?.message}
    </Alert>
  ) : null;
};

export default RealtimeBanner;
