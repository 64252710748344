import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Stack, Box, Typography } from '@mui/material';
//
import Logo from './Logo';
import ProgressBar from './ProgressBar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  isDashboard: PropTypes.bool,
  isCampaign: PropTypes.bool,
  isMailbox: PropTypes.bool,
  mobileBlock: PropTypes.bool,
  loadingMessage: PropTypes.string,
  children: PropTypes.node,
};

export default function LoadingScreen({ children, isDashboard, isCampaign, isMailbox, mobileBlock, loadingMessage, ...other }) {
  return (
    <>
      <ProgressBar />

      {!isDashboard && !isCampaign && (
        <>
          <Box sx={{ position: 'relative' }}>
            {mobileBlock && !isMailbox && (
              <Blocker>
                <Typography variant="h3">
                  Please visit Maility <br /> from a desktop device
                </Typography>
              </Blocker>
            )}
          </Box>
          <RootStyle {...other}>
            <Stack alignItems="center" justifyContent="center" spacing={8}>
              <Stack direction="row" alignItems="center" justifyContent="center">
                <m.div
                  initial={{ rotateY: 0 }}
                  animate={{ rotateY: 360 }}
                  transition={{
                    duration: 2,
                    ease: 'easeInOut',
                    repeatDelay: 1,
                    repeat: Infinity,
                  }}
                >
                  <Logo disabledLink sx={{ width: 64, height: 64 }} />
                </m.div>

                <Box
                  sx={{
                    position: 'absolute',
                    pt: 24,
                    px: 6,
                    textAlign: 'center',
                  }}
                >
                  {loadingMessage}
                </Box>

                <Box
                  component={m.div}
                  animate={{
                    scale: [1.2, 1, 1, 1.2, 1.2],
                    rotate: [270, 0, 0, 270, 270],
                    opacity: [0.25, 1, 1, 1, 0.25],
                    borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                  }}
                  transition={{ ease: 'linear', duration: 3.2, repeat: Infinity }}
                  sx={{
                    width: 100,
                    height: 100,
                    borderRadius: '25%',
                    position: 'absolute',
                    border: (theme) => `solid 3px ${alpha(theme.palette.primary.dark, 0.24)}`,
                  }}
                />

                <Box
                  component={m.div}
                  animate={{
                    scale: [1, 1.2, 1.2, 1, 1],
                    rotate: [0, 270, 270, 0, 0],
                    opacity: [1, 0.25, 0.25, 0.25, 1],
                    borderRadius: ['25%', '25%', '50%', '50%', '25%'],
                  }}
                  transition={{
                    ease: 'linear',
                    duration: 3.2,
                    repeat: Infinity,
                  }}
                  sx={{
                    width: 120,
                    height: 120,
                    borderRadius: '25%',
                    position: 'absolute',
                    border: (theme) => `solid 8px ${alpha(theme.palette.primary.dark, 0.24)}`,
                  }}
                />
              </Stack>
              {children}
            </Stack>
          </RootStyle>
        </>
      )}
    </>
  );
}

const Blocker = styled(Box)`
  position: fixed;
  top: 15%;
  height: 100%;
  width: 100%;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2em;
  padding-top: 6em;
  z-index: 99999999;
`;
