import PropTypes from 'prop-types';
import { createContext, useContext, useReducer } from 'react';

const defaultGlobalState = {
  notInvited: false,
  emailNotInvited: '',
  confetti: false,
};

const globalStateContext = createContext(defaultGlobalState);
const dispatchStateContext = createContext(undefined);

export const GlobalState = ({ children }) => {
  const [state, dispatch] = useReducer((state, newValue) => ({ ...state, ...newValue }), defaultGlobalState);

  return (
    <globalStateContext.Provider value={state}>
      <dispatchStateContext.Provider value={dispatch}>{children}</dispatchStateContext.Provider>
    </globalStateContext.Provider>
  );
};

GlobalState.propTypes = {
  children: PropTypes.node,
};

export const useGlobalState = () => [useContext(globalStateContext), useContext(dispatchStateContext)];
